<template>
  <div id="reset-password-email">
    <v-card class="reset-password-email-card" elevation="2">
      <v-toolbar class="reset-password-email-card__toolbar" height="84px" flat>
        <v-toolbar-title> {{ $t("Auth.ForgotYourPassword") }} </v-toolbar-title>
        <span> {{ $t("Auth.ResetMyPasswordHelper") }} </span>
      </v-toolbar>

      <v-divider />

      <v-card-text>
        <form v-if="!isShow" class="reset-password-email-form" @submit.prevent="send">
          <v-text-field
            v-model.trim="email"
            prepend-icon="mdi-email"
            :error-messages="emailErrors"
            :label="$t('Auth.Email')"
            required
            @blur="$v.email.$touch()"
          ></v-text-field>

          <v-btn type="submit" class="mt-4 mr-4" color="primary" :loading="isBusy" depressed block>
            {{ $t("Auth.ResetMyPasswordButton") }}
          </v-btn>
        </form>

        <v-alert
          v-model="isShow"
          class="mt-5"
          :color="!errorCode ? 'success' : 'deep-orange'"
          :icon="!errorCode ? 'mdi-check' : 'mdi-fire'"
          text
          outlined
          :dismissible="!!errorCode"
          >{{ alertMessage }}</v-alert
        >
      </v-card-text>
    </v-card>
    <div class="d-flex flex-column flex-sm-row justify-center align-center mt-4">
      <span>{{ $t("Auth.RememberYourPassword") }}</span>
      <router-link :to="{ name: 'login' }" class="ml-0 mt-2 ml-sm-4 mt-sm-0">
        <b>{{ $t("Auth.SignIn") }}</b>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL } from "../../../config";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Email",

  middleware: "guest",

  layout: "auth",

  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },

  metaInfo() {
    return { title: this.$t("Auth.ResetPassword") };
  },

  data: () => ({
    email: "",

    isShow: false,
    errorCode: null,

    isBusy: false,
  }),

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push(this.$t("Validation.MailRequired"));
      return errors;
    },

    alertMessage() {
      if (this.errorCode === "CAN_SEND_CONFIRMATION_EMAIL") return "Can't send confirmation E-Mail";
      return this.$t("Auth.ResetPasswordLinkSent");
    },
  },

  methods: {
    async send() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isBusy = true;
      this.errorCode = null;

      try {
        await axios.post(`${API_URL}/password/reset`, {
          email: this.email,
        });
      } catch ({ response }) {
        const { data } = response;
        this.errorCode = data.code || null;
      } finally {
        this.isShow = true;
        this.isBusy = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password-email-card {
  &__toolbar {
    ::v-deep .v-toolbar__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
</style>
